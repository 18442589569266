<template>
  <div>
    <v-app-bar :style="[mini ? { 'left': '56px' } : { 'left': '232px' }]" app dense flat outlined>
      <h6>{{ title }}</h6>
      <v-spacer v-if="!inactiveListings || getPendingListing === 0"></v-spacer>
      <v-row class="d-flex align-center justify-center"  v-if="inactiveListings && inactiveListings === true && getPendingListing && getPendingListing > 0">
        <p class="mb-0"><span class="font-bold">{{getPendingListing}}</span> Pending <span v-if="getPendingListing > 1">Listings</span> <span v-else>Listing</span></p>
        <v-btn class="ml-1 black--text" color="accent" depressed small @click="pay">ACTIVATE NOW</v-btn>
      </v-row>
      <v-spacer v-if="!inactiveListings || getPendingListing === 0"></v-spacer>
      <v-btn class="mr-2" icon small>
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small icon color="secondary" @click="logout" bottom left v-bind="attrs" v-on="on">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </v-app-bar>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "TopNavBar",
  props: ['mini', 'inactiveListings'],
  data() {
    return {
      title: 'Home',
    };
  },
  mounted() {
    this.title = this.capitalizeTheFirstLetterOfEachWord(this.$route.name);
  },
  computed:{
    ...mapGetters(['getPendingListing']),
  },
  methods: {
    capitalizeTheFirstLetterOfEachWord(words='') {
      if ( words !== '' && words !== null ) { words = words.replace('-', ' '); }
      let separateWord = words.toLowerCase().split(' ');
      for (let i = 0; i < separateWord.length; i++) {
        separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
            separateWord[i].substring(1);
      }
      return separateWord.join(' ');
    },
    async pay(){
      await this.loadAllDrafts();
      this.$emit('pay');
    },
    ...mapActions(['logout','loadAllDrafts']),
  },
  watch: {
    '$route' (to, from) {
      this.title = this.capitalizeTheFirstLetterOfEachWord(to.name) || 'Home';
    }
  }
}
</script>